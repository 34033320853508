import $ from 'jquery'
import {} from 'jquery-validation'
import {} from 'bootstrap'
import WOW from 'wow.js'
import {} from 'cardslider'
import {} from 'slick-carousel'
import debounce from 'lodash/debounce'

import Turbolinks from 'turbolinks'
import Rails from '@rails/ujs'

window.$ = $
window.jQuery = $
window.WOW = WOW

Turbolinks.start()

function handleServerError() {
  const serverErrors = document.getElementsByClassName('invalid-feedback')

  for (const element of serverErrors) {
    const parentNode = element.parentNode
    const errorPlaceholder = $(parentNode).find('.error-placeholder')
    errorPlaceholder && errorPlaceholder.remove()
    const frontendError = $(parentNode).find('.invalid-feedback .error')
    frontendError && frontendError.remove()
  }
}

function adjustPageHeight() {
  // adjust main and .page element heights to compensate for .overlay-section movements
  // only do it on the homepage though
  if (window.location.pathname === '/' || window.location.pathname === '/home') {
    let footerTop = $('footer').offset().top + $('body').scrollTop()
    let footerHeight = $('footer').outerHeight()
    $('main').outerHeight(footerTop)
    $('.page').outerHeight(footerTop + footerHeight)
  }
}

// signup form on homepage functions
function addParticipantField() {
  // eslint-disable-next-line max-len
  const participantInput = '<div style="display:none;" class="form-label-group input-group email optional signup_participants"><input class="form-control base string optional mt-2" placeholder="client@email.com" type="email" name="signup[participants][]" id="signup_participants"><label class="form-control-label email optional" for="signup_participants">Add People</label></div>'
  if ($('#email_list .signup_participants').last().find('input').val() !== '') {
    $('#email_list').append($(participantInput).fadeIn())
  }
  $('#email_list .signup_participants').last().find('input').trigger('focus')
}

function showValidIcon(currentEl) {
  const validInputGroup = currentEl.parent('.form-group-valid')
  const icon = $('.valid-icon')
  if (!(validInputGroup.find(icon).length)) {
    validInputGroup.prepend(icon.first().clone().show())
  }
}

function handleFormUpdate(currentEl) {
  showValidIcon(currentEl)
  addParticipantField()

  if ($('#email_list .signup_participants').length === 1) {
    // update section title
    $('.quick-sign-up h1 span').first().removeClass('active')
    $('.create-workspace .text-animation span').addClass('active')
    //update form title
    $('#form-title').text('Add Other People')
    // show submit button
    $('.form-btn-container').fadeIn()
    // add 'first' class for arrow positioning
    $('#email_list .signup_participants').first().addClass('first')
  }
}

function ready() {
  handleServerError()

  // page load fade in - adjust page height after the fade in, otherwise it gets the wrong values
  $('.page-loader').delay(900).fadeOut(700, function() {
    $('body').fadeIn( 'fast', 'linear',function () {
      adjustPageHeight()
    })
  })

  // mobile menu button
  $('.menu-icon').on('click', function() {
    $('body').toggleClass('open-menu')
  })

  // shrink header on scroll
  function scrolledHeader() {
    var scroll = $('body').scrollTop()
    if (scroll > 80) {
      $('.header').addClass('scrolled')
    } else {
      $('.header').removeClass('scrolled')
    }
  }
  $('body').on('scroll', debounce(scrolledHeader, 100))

  // init animate on scroll
  var wow = new WOW({
    offset: 1,
    mobile: true,
    live: false,
    scrollContainer: 'body'
  })
  wow.init()

  function setNextSpanToVisible() {
    let span
    if ($('.text-animation .is-visible').next().is('span')) {
      span = $('.text-animation .is-visible').next()
    } else {
      span = $('.text-animation span:first-child')
    }
    $('.text-animation .is-visible').addClass('was-visible').removeClass('is-visible')
    $(span).removeClass('was-visible').addClass('is-visible')
  }
  setInterval(setNextSpanToVisible, 5000)

  // index slider
  // if ($('.my-slider').length) {
    // var cardslider = $('.my-slider').cardslider({
    //   swipe: true,
    //   dots: false,
    //   direction: 'left',
    // }).data('cardslider')

    // var progressBarInterval

    // function updateProgressBar(el) {
    //   var timeleft = 0
    //   //$(el).parent().addClass('active')
    //
    //   progressBarInterval = setInterval(function() {
    //     if (timeleft >= 100) {
    //       clearInterval(progressBarInterval)
    //     }
    //     //$(el).css('width', timeleft + '%')
    //     timeleft += 1
    //   }, 100)
    // }

    // var count = 0
    // function transition() {
      // clearInterval(progressBarInterval)
      // $('.slider-nav .item').removeClass('active')
      // $('.line-value').css('width', '0%')
      //
      // if (count === 0) {
      //   cardslider.changeCardTo(count)
      //   updateProgressBar('.nav-chat .line-value')
      //   count = 1
      // } else if (count === 1) {
      //   cardslider.changeCardTo(count)
      //   updateProgressBar('.nav-calls .line-value')
      //   count = 2
      // } else if (count === 2) {
      //   updateProgressBar('.nav-meetings .line-value')
      //   cardslider.changeCardTo(count)
      //   count = 3
      // } else if (count === 3) {
      //   cardslider.changeCardTo(count)
      //   updateProgressBar('.nav-projects .line-value')
      //   count = 4
      // } else if (count === 4) {
      //   cardslider.changeCardTo(count)
      //   updateProgressBar('.nav-platforms .line-value')
      //   count = 0
      // }
      // setNextSpanToVisible()
    // }

    // var transitionInterval = setInterval(transition, 11000)
    // start transitions on page load
    //transition()

    // $('.nav-chat').on('click', function() {
    //   count = 0
    //   clearInterval(transitionInterval)
    //   transition()
    // })
    //
    // $('.nav-calls').on('click', function() {
    //   count = 1
    //   clearInterval(transitionInterval)
    //   transition()
    // })
    //
    // $('.nav-meetings').on('click', function() {
    //   count = 2
    //   clearInterval(transitionInterval)
    //   transition()
    // })
    //
    // $('.nav-projects').on('click', function() {
    //   count = 3
    //   clearInterval(transitionInterval)
    //   transition()
    // })
    //
    // $('.nav-platforms').on('click', function() {
    //   count = 4
    //   clearInterval(transitionInterval)
    //   transition()
    // })
  // }

  // features slider
  if ($('.slider').length) {
    $('.slider').slick({
      infinite: true,
      arrows: false,
      dots: false,
      autoplay: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
    })

    $('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.progressBarContainer .item').removeClass('active')
      $('.progressBarContainer .item' + nextSlide).addClass('active')
    })

    //ticking machine
    var percentTime
    var tick
    var time = .1
    var progressBarIndex = 0

    $('.progressBarContainer .progressBar').each(function(index) {
      var progress = "<div class='inProgress inProgress" + index + "'></div>"
      $(this).html(progress)
    })

    function startProgressbar() {
      resetProgressbar()
      percentTime = 0
      tick = setInterval(interval, 10)
    }

    function interval() {
      if (($('.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr('aria-hidden')) === 'true') {
        progressBarIndex = $('.slider .slick-track div[aria-hidden="false"]').data('slickIndex')
        startProgressbar()
      } else {
        percentTime += 1 / (time + 5)
        $('.inProgress' + progressBarIndex).css({
          width: percentTime + '%'
        })
        if (percentTime >= 100) {
          $('.single-item').slick('slickNext')
          progressBarIndex++
          if (progressBarIndex > 2) {
            progressBarIndex = 0
          }
          startProgressbar()
        }
      }
    }

    function resetProgressbar() {
      $('.inProgress').css({
        width: 0 + '%'
      })
      clearInterval(tick)
    }
    startProgressbar()
    // End ticking machine

    $('.item').on('click', function() {
      clearInterval(tick)
      var goToThisIndex = $(this).find('span').data('slickIndex')
      $('.single-item').slick('slickGoTo', goToThisIndex, false)
      startProgressbar()
    })
  }

  // smooth scroll-to on FAQ page
  $('[data-scroll-to]').on('click', function() {
    var element = $(this).attr('data-scroll-to'),
      headerOffset = 120,
      bodyScrollPos = document.querySelector('body').scrollTop,
      scrollSpeed = 500

    $('html, body').animate({
      scrollTop: $(element).offset().top + bodyScrollPos - headerOffset
    }, scrollSpeed)
  })

  // sign up form validation
  $('#home-form-container form').validate({
    rules: {
      'signup[email]': {
        required: true,
        email: true
      },
      'participants[email]': {
        email: true
      }
    },
    messages: {
      'signup[email]': {
        required: 'Please enter a valid email.',
      },
      'participants[email]': {
        required: 'Please enter a valid email.',
      }
    },
    errorElement: 'div',
    errorPlacement: function (error, element) {
      const formErrors = $('#home-form-container form #form-errors')
      formErrors.empty().append(error)
    },
    highlight: function (element) {
      $(element).addClass('is-invalid').removeClass('is-valid')
    },
    unhighlight: function (element) {
      $(element).addClass('is-valid').removeClass('is-invalid')
    }
  })

  // focus first input on page load - commented out to prevent page from scrolling on load
  // $('#home-form-container .signup_email input').trigger('focus')

}

// handle enter keypress on signup form
$(document).on('keypress', '#home-form-container input',function(e) {
  if (e.which == 13) {
    const currentEl = $('#home-form-container input:focus')

    if (currentEl.val() !== '' && currentEl.valid()) {
      currentEl.parent().addClass('form-group-valid')

      if ($('#email_list .signup_participants').length < 6) {
        $('.page, main').height($('.page').height() + 50)

        $('.overlay-section').animate({
            top: "+=50",
          },200,
          'linear',
          function() {
            handleFormUpdate(currentEl)
            adjustPageHeight()
          })
      } else {
        // submit the form
        $('#home-form-container form').trigger('submit')
        return
      }
    }
    // cancels form submit on 'enter'
    return false
  }
})

// adjust page height/footer position on resize
$(window).on('resize', debounce(adjustPageHeight, 100))

document.addEventListener('click', function (e) {
  // handle product tour video playback
  if (e.target.classList.contains('play-product-tour')) {
    var video = document.getElementById('product-tour-video')
    var container = document.querySelector('.product-tour-container')
    container.classList.add('active')
    video.play()
    setTimeout( () => {
      container.removeChild(e.target)
    }, 200)
  }

  // handle dropdowns on downloads page
  if (e.target.classList.contains('dropdown-trigger')) {
    e.preventDefault()
    e.target.classList.toggle('dropdown-open')
  } else {
    if (document.querySelector('.dropdown-trigger') != null) {
      document
        .querySelectorAll('.dropdown-trigger')
        .forEach(function (dropdown) {
          dropdown.classList.remove('dropdown-open')
        })
    }
  }
})

$(document).on('turbolinks:load', ready)
